import type { Action } from 'svelte/action';

type Params = { callback: () => void; trigger: Element };

export const clickoutside: Action<Element, Params> = (
  node,
  { callback, trigger },
): { destroy: () => void; update: (params: Params) => void } => {
  function onClick(event: Event) {
    if (
      node &&
      trigger &&
      trigger !== event.target &&
      !node.contains(event.target as HTMLElement)
    ) {
      callback();
    }
  }

  window.addEventListener('click', onClick);

  return {
    update({ trigger: newTrigger }) {
      trigger = newTrigger;
    },
    destroy() {
      window.removeEventListener('click', onClick);
    },
  };
};

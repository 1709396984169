<script lang="ts">
  import type { FAQ } from '$lib/contentful/models/faq';
  import Text from '$lib/components/text/text.svelte';
  import RichText from '../rich-text/rich-text.svelte';
  import Box from '$lib/layout/grid/box.svelte';
  import Grid from '$lib/layout/grid/grid.svelte';

  export let content: FAQ;
  $: ({ title, entityId, questionsAndAnswers } = content);
</script>

<Grid as="div" layout="columns">
  <Box as="div" class="flex flex-col gap-8">
    <Text {entityId} fieldId="title" variant="display-68">{title}</Text>
    {#each questionsAndAnswers as { question, answer, entityId }}
      <div>
        <Text fieldId="question" {entityId} variant="body-24">{question}</Text>
        <RichText theme="dark" fieldId="answer" {entityId} text={answer} />
      </div>
    {/each}
  </Box>
</Grid>

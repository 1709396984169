<script lang="ts">
  import Text from '$lib/components/text/text.svelte';
  import Box from '$lib/layout/grid/box.svelte';
  import Grid from '$lib/layout/grid/grid.svelte';
  import type { TabGroup } from '$lib/contentful/models/tab-group';
  import CodeBlock from '$lib/components/code-block/code-block.svelte';
  import Icon from '../icon/icon.svelte';
  import RichText from '../rich-text/rich-text.svelte';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';
  import ResourceCard from '../filterable-resources/resource-card.svelte';
  import MobileCarousel from '../mobile-carousel/mobile-carousel.svelte';

  export let content: TabGroup | undefined;

  let activeTab = 0;
</script>

{#if content}
  {@const { tabs, heading, description, eyebrow } = content}
  <Grid as="div" layout="columns">
    <Box as="div" class="flex flex-col gap-20">
      <div class="flex flex-col gap-4">
        {#if eyebrow}
          <Text
            as="h3"
            variant="eyebrow-16"
            class="text-mint"
            entityId={content.entityId}
            fieldId="eyebrow"
          >
            {eyebrow}
          </Text>
        {/if}
        {#if heading}
          <Text
            variant="display-68"
            entityId={content.entityId}
            fieldId="heading">{heading}</Text
          >
        {/if}
        {#if description}
          <Text
            class="text-indigo-100"
            as="p"
            variant="body-24"
            entityId={content.entityId}
            fieldId="description"
          >
            {description}
          </Text>
        {/if}
      </div>
      <div class="flex max-xl:flex-col xl:flex-row items-start gap-12">
        <div class="flex flex-col max-xl:hidden w-1/2 min-w-fit">
          {#each tabs as { title, entityId, icon }, idx}
            <button
              on:click={() => (activeTab = idx)}
              class:active={activeTab === idx}
              class="tab"
              aria-selected={activeTab === idx}
              aria-controls="panel-{idx}"
              id="tab-{idx}"
              data-contentful-entry-id={entityId}
              data-contentful-field-id={'title'}
              role="tab"
            >
              {#if icon !== null}
                <Icon name={icon} />
              {/if}
              {title}
            </button>
          {/each}
        </div>
        {#each tabs as { heading, body, codeBlock, image, entityId, icon, title, resources }, idx}
          <button
            on:click={() => (activeTab = idx)}
            class:active={activeTab === idx}
            class="tab tab-sm"
            aria-selected={activeTab === idx}
            aria-controls="panel-{idx}"
            id="tab-{idx}"
            data-contentful-entry-id={entityId}
            data-contentful-field-id={'title'}
            role="tab"
          >
            {#if icon !== null}
              <Icon name={icon} />
            {/if}
            {title}
          </button>
          <div
            class="tab-panel"
            class:active={activeTab === idx}
            role="tabpanel"
            id="panel-{idx}"
            aria-labelledby="tab-{idx}"
          >
            <div class="tab-text-block">
              {#if heading}
                <Text as="h3" variant="heading-48" {entityId} fieldId="heading"
                  >{heading}</Text
                >
              {/if}
              {#if body}
                <RichText theme="dark" {entityId} fieldId="body" text={body} />
              {/if}
            </div>
            <div class="tab-content-block">
              {#if codeBlock}
                <CodeBlock content={codeBlock} />
              {:else if image}
                <ContentfulImage
                  class="max-h-[400px] mx-auto"
                  src={image.url}
                  alt={image.title}
                  height={400}
                />
              {:else if resources}
                <MobileCarousel items={resources} let:item>
                  <ResourceCard resource={item} />
                </MobileCarousel>
              {/if}
            </div>
          </div>
        {/each}
      </div>
    </Box>
  </Grid>
{/if}

<style lang="postcss">
  .tab-panel {
    @apply hidden w-full overflow-auto;

    &.active {
      @apply flex flex-col gap-6;
    }
  }

  .tab-text-block {
    @apply flex flex-col gap-6;
  }

  .tab {
    @apply relative flex flex-row gap-6 items-center p-6 text-left border-b-2 border-blue-gray-900 text-xl font-sans text-blue-gray-300 hover:text-blue-gray-100;

    &.tab-sm {
      @apply xl:hidden w-full;
    }

    &.active {
      @apply text-white after:content-[''] after:absolute after:-bottom-[3px] after:left-0 after:h-[4px] after:bg-gradient-ultraviolet after:w-full;
    }
  }
</style>

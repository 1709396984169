import type { ActionReturn } from 'svelte/action';

type Attributes = {
  'on:swipeleft': () => void;
  'on:swiperight': () => void;
};

export function swipe(
  element: HTMLDivElement,
): ActionReturn<undefined, Attributes> {
  let xDown: number | null = null;
  let yDown: number | null = null;

  function handleTouchStart(event: TouchEvent) {
    const firstTouch = event.touches[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(event: TouchEvent) {
    event.preventDefault();
    if (!xDown || !yDown) {
      return;
    }

    const xUp = event.touches[0].clientX;
    const yUp = event.touches[0].clientY;

    const xDiff = xDown - xUp;
    const yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        element.dispatchEvent(new CustomEvent('swiperight'));
      } else {
        element.dispatchEvent(new CustomEvent('swipeleft'));
      }
    }

    xDown = null;
    yDown = null;
  }

  element.addEventListener('touchstart', handleTouchStart, false);
  element.addEventListener('touchmove', handleTouchMove, false);

  return {
    destroy: () => {
      element.removeEventListener('touchstart', handleTouchStart, false);
      element.removeEventListener('touchmove', handleTouchMove, false);
    },
  };
}

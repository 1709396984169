<script lang="ts">
  import Grid from '$lib/layout/grid/grid.svelte';
  import Box from '$lib/layout/grid/box.svelte';
  import Text from '$lib/components/text/text.svelte';
  import type { SectionHeading } from '$lib/contentful/models/section-heading';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';
  import Icon from '../icon/icon.svelte';

  export let content: SectionHeading = {
    icon: null,
    alignment: 'left',
    heading: '',
    body: '',
    image: undefined,
    contentType: 'sectionHeading',
    entityId: '',
  };
  export let id: string = '';

  $: ({ heading, body, image, entityId, icon, iconColor, alignment } = content);
</script>

<Grid as="div" layout="columns" {id}>
  <Box
    as="div"
    class="flex w-full max-lg:flex-col lg:flex-row max-lg:gap-8 lg:gap-24 border-b-2 pb-8 border-indigo-100/10 {alignment ==
    'center'
      ? 'justify-center'
      : 'justify-start'}"
  >
    <div
      class="flex flex-col gap-4 {alignment === 'center'
        ? 'items-center'
        : 'items-start'}"
    >
      {#if icon}
        <Icon width={48} height={48} name={icon} variant={iconColor} />
      {/if}
      {#if heading || $$slots['heading']}
        <Text as="h2" variant="display-68" {entityId} fieldId="heading">
          <slot name="heading">{heading}</slot>
        </Text>
      {/if}
      {#if body || $$slots['body']}
        <Text as="p" variant="body-32" {entityId} fieldId="body">
          <slot name="body">{body}</slot>
        </Text>
      {/if}
    </div>
    {#if image}
      <div class="flex max-lg:full lg:w-[48rem] justify-center items-center">
        <ContentfulImage
          alt={image.description ?? image.title}
          src={image.url}
        />
      </div>
    {/if}
  </Box>

  <Box as="div">
    <slot />
  </Box>
</Grid>

<script lang="ts">
  export let index = 0;
  export let lastItem = false;
  export let direction: 'row' | 'col' | 'col-reverse';
</script>

<div
  class="relative {direction === 'row'
    ? 'h-full'
    : 'h-full lg:h-[275%] 4xl:h-[350%]'}"
>
  <div class="line" class:last-item={lastItem} />
  <div class="index">
    {index + 1}
  </div>
</div>

<style lang="postcss">
  .index {
    @apply lg:absolute flex items-center justify-center font-mono rounded-full border-2 bg-space-black border-pink-200 h-8 w-8 top-4 left-1/2;
  }

  .line {
    /* height is 100% of the container + 12rem (which is half the padding of the container) */
    @apply hidden lg:block absolute top-8 left-[calc(50%_+_14px)] w-[4px] h-[calc(100%_+_12rem)] bg-gradient-ultraviolet;
  }

  .last-item {
    @apply hidden;
  }
</style>

import { type Writable, writable } from 'svelte/store';

import type { Resource } from '$lib/contentful/models/resource';

export type AppliedFilters = Record<string, string[]>;
export type AppliedFiltersStores = Record<string, Writable<AppliedFilters>>;

export const appliedFiltersStores: AppliedFiltersStores = {};

export const createStore = (
  searchParams: URLSearchParams,
): Writable<AppliedFilters> => {
  return writable(parseFilters(searchParams));
};

export const parseFilters = (searchParams: URLSearchParams): AppliedFilters => {
  const filters: AppliedFilters = {};

  searchParams.forEach((values, key) => {
    filters[key] = values.split(',');
  });

  return filters;
};

export const serializeFilters = (filters: AppliedFilters): URLSearchParams => {
  const params = new URLSearchParams();

  Object.entries(filters).forEach(([key, values]) => {
    if (values.length) {
      params.set(key, values.join(','));
    }
  });

  return params;
};

export const compareResource = (
  resource: Resource,
  filters: AppliedFilters,
): boolean => {
  let include = true;

  Object.entries(filters).forEach(([key, values]) => {
    if (key in resource && values.length) {
      const value = resource[key];
      include = !!value && typeof value === 'string' && values.includes(value);
    }
  });

  return include;
};

export const group = (
  resources: Resource[],
  groupSize: number,
): Resource[][] => {
  const groups: Resource[][] = [];

  for (let i = 0; i < resources.length; i += groupSize) {
    groups.push(resources.slice(i, i + groupSize));
  }

  return groups;
};

export const filterResources = (
  filters: AppliedFilters,
  resources: Resource[] | undefined,
): Resource[] => {
  if (resources === undefined) return [];
  if (!filters) return resources;

  return resources.filter((resource) => {
    return compareResource(resource, filters);
  });
};

export const applyFilter = (
  event: Event & { currentTarget: EventTarget & HTMLInputElement },
  field: string,
  anchor: string,
) => {
  const { value, checked } = event.currentTarget;

  appliedFiltersStores[anchor].update((existingFilters) => {
    const newFilters: AppliedFilters = {
      ...existingFilters,
    };

    if (checked) {
      newFilters[field] = existingFilters[field]
        ? [...existingFilters[field], value]
        : [value];
    } else {
      newFilters[field] = existingFilters[field]
        ? existingFilters[field].filter((option) => option !== value)
        : [];
    }

    return newFilters;
  });
};

<script lang="ts">
  import type { CloudHero } from '$lib/contentful/models/cloud-hero';
  import Box from '$lib/layout/grid/box.svelte';
  import Grid from '$lib/layout/grid/grid.svelte';
  import CloudHeroContent from './cloud-hero-content.svelte';

  export let content: CloudHero | undefined;
</script>

{#if content}
  <Grid as="div" layout="columns" background="stars" class="max-xl:hidden">
    <Box as="div" span={7} offset={2} class="flex items-center justify-center">
      <enhanced:img
        src="/static/images/globe.png"
        alt="Globe with pixelated continents and orbitting dashed lines"
        class="max-w-3xl p-10"
      />
    </Box>
    <Box as="div" span={4} offset={9} class="flex items-center">
      <CloudHeroContent {content} />
    </Box>
  </Grid>

  <Grid
    as="div"
    layout="columns"
    background="stars"
    class="max-sm:hidden xl:hidden"
  >
    <Box as="div" class="flex flex-col gap-8">
      <CloudHeroContent {content} />
      <div class="pt-24 flex items-center justify-center">
        <enhanced:img
          src="/static/images/globe.png"
          alt="Globe with pixelated continents and orbitting dashed lines"
          class="w-full"
        />
      </div>
    </Box>
  </Grid>

  <div
    class="sm:hidden flex flex-col gap-8 pt-12 bg-stars bg-background bg-cover"
  >
    <div class="px-5">
      <CloudHeroContent {content} />
    </div>
    <div class="h-80 relative overflow-hidden">
      <enhanced:img
        src="/static/images/globe.png"
        alt="Globe with pixelated continents and orbitting dashed lines"
        class="!max-w-[unset] w-[120%] absolute top-0 left-[-10%]"
      />
    </div>
  </div>
{/if}

<style lang="postcss">
</style>

<script lang="ts">
  import type { Image } from '$lib/contentful/models/image';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';

  export let image: Image | undefined;
</script>

<div class="browser-content">
  <slot />
  <div class="browser-frame">
    <div class="browser-btns">
      <div class="browser-btn close" />
      <div class="browser-btn minimize" />
      <div class="browser-btn maximize" />
    </div>
    {#if image}
      <ContentfulImage
        class="mt-8 w-full rounded-lg z-10"
        src={image.url}
        alt={image.description}
      />
    {/if}
  </div>
</div>

<style lang="postcss">
  .browser-content {
    @apply relative max-lg:h-[12rem] max-2xl:h-[24rem] 2xl:h-[32rem] max-lg:w-full lg:w-1/2;
  }

  .browser-frame {
    @apply absolute max-lg:top-20 max-lg:left-0 lg:w-[1440px] max-lg:w-fit rounded-t-lg bg-blue-gray-50 border-blue-gray-50 border-4;
  }

  .browser-btns {
    @apply absolute top-2 left-2 w-full h-8 flex flex-row gap-2;
  }

  .browser-btn {
    @apply h-3 w-3 rounded-lg;

    &.close {
      @apply bg-red-300;
    }

    &.minimize {
      @apply bg-yellow-300;
    }

    &.maximize {
      @apply bg-green-300;
    }
  }
</style>

<script lang="ts">
  import { twMerge } from 'tailwind-merge';
  import type { Filter } from '$lib/contentful/models/filterable-resources';
  import { clickoutside } from '$lib/utilities/click-outside';
  import type { Writable } from 'svelte/store';
  import { applyFilter } from '$lib/stores/filters';

  export let filters: Filter[];
  export let anchor: string;
  export let appliedFilters: Writable<Record<string, string[]>>;
  export let entityId: string;
  export let fieldId: string = 'filters';
</script>

<div
  data-contentful-field-id={fieldId}
  data-contentful-entry-id={entityId}
  class={twMerge('filter-wrapper', $$props.class)}
>
  <p class="max-lg:text-sm lg:text-base font-mono text-blue-gray-400">FILTER</p>

  <div class="filters">
    {#each filters as filter}
      {@const { field, label, options } = filter}

      <div class="relative">
        <button
          bind:this={filter.trigger}
          on:click={() => (filter.open = !filter.open)}
          class="filter-btn">{label}</button
        >
        {#if filter.open}
          <menu
            class="filter-dropdown"
            use:clickoutside={{
              callback: () => (filter.open = false),
              trigger: filter.trigger,
            }}
          >
            {#each options as option}
              <li>
                <label class="filter">
                  <input
                    id={option}
                    type="checkbox"
                    value={option}
                    checked={$appliedFilters[field] &&
                      $appliedFilters[field].includes(option)}
                    on:change={(event) => applyFilter(event, field, anchor)}
                  />
                  {option}
                </label>
              </li>
            {/each}
          </menu>
        {/if}
      </div>
    {/each}
  </div>
</div>

<style lang="postcss">
  .filter-wrapper {
    @apply px-6 py-4 gap-8 items-center max-sm:hidden sm:flex flex-row justify-between bg-deeper-blue rounded-lg;
  }

  .filters {
    @apply flex flex-row gap-8;
  }

  .filter-btn {
    @apply max-lg:text-sm lg:text-lg text-off-white appearance-none whitespace-nowrap;
  }

  .filter-dropdown {
    @apply z-50 absolute p-8 bg-deeper-blue rounded-lg flex flex-col gap-4;
  }

  .filter {
    @apply cursor-pointer flex flex-row items-center gap-4 whitespace-nowrap;
  }
</style>

<script lang="ts">
  import { twMerge } from 'tailwind-merge';

  import Text from '../text/text.svelte';
  import Icon from '../icon/icon.svelte';
  import type { Filter } from '$lib/contentful/models/filterable-resources';
  import type { Writable } from 'svelte/store';
  import { applyFilter } from '$lib/stores/filters';

  export let filters: Filter[];
  export let anchor: string;
  export let appliedFilters: Writable<Record<string, string[]>>;
  export let entityId: string;
  export let fieldId: string = 'filters';

  let open = false;
</script>

<div
  data-contentful-field-id={fieldId}
  data-contentful-entry-id={entityId}
  class={twMerge('filter-wrapper', $$props.class)}
>
  <button class="filter-btn" on:click={() => (open = !open)}>
    <Text as="span" variant="mono-16" class="text-blue-gray-400">FILTER</Text>
    <Icon name={open ? 'chevron-up' : 'chevron-down'} />
  </button>
  {#if open}
    <div class="filters">
      {#each filters as filter}
        {@const { field, label, options } = filter}
        <button
          on:click={() => (filter.open = !filter.open)}
          class="filter-btn"
        >
          {label}
          <Icon name={filter.open ? 'chevron-up' : 'chevron-down'} />
        </button>

        {#if filter.open}
          {#each options as option}
            <label class="filter">
              <input
                id={option}
                type="checkbox"
                value={option}
                checked={$appliedFilters[field] &&
                  $appliedFilters[field].includes(option)}
                on:change={(event) => applyFilter(event, field, anchor)}
              />
              {option}
            </label>
          {/each}
        {/if}
      {/each}
    </div>
  {/if}
</div>

<style lang="postcss">
  .filter-wrapper {
    @apply w-full py-6 px-4 bg-deeper-blue sm:hidden max-sm:flex flex-col items-start gap-4;
  }

  .filter-btn {
    @apply w-full text-off-white appearance-none flex justify-between;
  }

  .filters {
    @apply border-t border-graphite py-4 w-full flex flex-col gap-4;
  }

  .filter {
    @apply cursor-pointer flex flex-row items-center gap-4 whitespace-nowrap;
  }
</style>

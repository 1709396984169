const MAXIMUM_PAGE_BUTTONS = 9;
const MINIMUM_PAGE_BUTTONS = 1;

export const generatePageNumbers = (
  totalPages: number,
  currentPage: number,
): (number | undefined)[] => {
  if (totalPages <= MINIMUM_PAGE_BUTTONS) {
    return [];
  }

  if (totalPages > MINIMUM_PAGE_BUTTONS && totalPages <= MAXIMUM_PAGE_BUTTONS) {
    return new Array(totalPages).fill(0).map((_, idx) => idx + 1);
  }

  if (currentPage < 5) {
    return [
      1,
      2,
      3,
      4,
      5,
      undefined,
      totalPages - 2,
      totalPages - 1,
      totalPages,
    ];
  }

  if (currentPage >= 5 && currentPage <= totalPages - 5) {
    return [
      1,
      2,
      undefined,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      undefined,
      totalPages - 1,
      totalPages,
    ];
  }
  return [
    1,
    2,
    3,
    undefined,
    totalPages - 4,
    totalPages - 3,
    totalPages - 2,
    totalPages - 1,
    totalPages,
  ];
};

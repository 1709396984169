<script lang="ts">
  import Button from '$lib/components/button/button.svelte';
  import Text from '$lib/components/text/text.svelte';
  import type { CloudHero } from '$lib/contentful/models/cloud-hero';

  type $$Props = {
    content: CloudHero;
  };

  export let content: CloudHero;
  const { eyebrow, heading, body, callsToAction } = content;
</script>

<div class="flex flex-col gap-8">
  {#if eyebrow}
    <Text
      entityId={content.entityId}
      fieldId={'eyebrow'}
      class="text-indigo-300"
      variant="eyebrow-16">{eyebrow}</Text
    >
  {/if}
  {#if heading}
    <Text variant="heading-48" entityId={content.entityId} fieldId={'heading'}>
      {heading}
    </Text>
  {/if}
  {#if body}
    <Text entityId={content.entityId} fieldId={'body'} variant="body-24">
      {body}
    </Text>
  {/if}
  <div class="flex flex-row gap-4 lg:mt-16 max-sm:flex-col">
    {#each callsToAction as { text, ...rest }}
      <Button {...rest}>{text}</Button>
    {/each}
  </div>
</div>

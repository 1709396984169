<script lang="ts">
  import type { OrderedContent } from '$lib/contentful/models/content-block';
  import Text from '$lib/components/text/text.svelte';
  import OrderedContentBlock from './ordered-content-block.svelte';
  import Grid from '$lib/layout/grid/grid.svelte';
  import Box from '$lib/layout/grid/box.svelte';

  type $$Props = {
    content: OrderedContent | undefined;
  };

  export let content: OrderedContent | undefined;
</script>

{#if content}
  {@const { title, contentBlocks } = content}
  {#if title}
    <Grid as="div" layout="columns" class="lg:no-padding bg-stars bg-contain">
      <Box as="div">
        <Text variant="heading-68">{title}</Text>
      </Box>
    </Grid>
  {/if}
  <OrderedContentBlock content={contentBlocks[0]} index={0} />
  <OrderedContentBlock
    content={contentBlocks[1]}
    direction="col"
    leftSpan={6}
    index={1}
    diveDeeper
  />
  <OrderedContentBlock
    leftSpan={4}
    content={contentBlocks[2]}
    index={2}
    diveDeeper
  />
  <OrderedContentBlock leftSpan={4} content={contentBlocks[3]} index={3} />
  <OrderedContentBlock
    content={contentBlocks[4]}
    direction="col-reverse"
    index={4}
  />
  <OrderedContentBlock content={contentBlocks[5]} index={5} />
  <OrderedContentBlock leftSpan={4} content={contentBlocks[6]} index={6} />
  <OrderedContentBlock leftSpan={4} content={contentBlocks[7]} index={7} />
  <OrderedContentBlock
    leftSpan={4}
    content={contentBlocks[8]}
    index={8}
    lastItem
  />
{/if}

<script lang="ts">
  import { swipe } from '$lib/directives/swipe';

  import { v4 } from 'uuid';
  type T = $$Generic;

  const uuid = v4();
  export let items: T[];

  let active = 0;
  let carousel: HTMLDivElement;

  const scrollTo = (index: number) => {
    const elem = document.getElementById(`carousel-item-${uuid}-${index}`);
    if (elem) {
      const amount = index > active ? elem.clientWidth : -elem.clientWidth;
      carousel.scrollBy({ left: amount, behavior: 'smooth' });
      active = index;
    }
  };
</script>

<div
  role="grid"
  class="carousel"
  bind:this={carousel}
  use:swipe
  on:swiperight={() => scrollTo(active + 1)}
  on:swipeleft={() => scrollTo(active - 1)}
>
  {#each items as item, idx}
    <div id="carousel-item-{uuid}-{idx}" class="item">
      <slot {item} />
    </div>
  {/each}
</div>
<div class="indices">
  {#each items as _, idx}
    <button
      on:click={() => scrollTo(idx)}
      class="index"
      class:active={active === idx}
    />
  {/each}
</div>

<style lang="postcss">
  .carousel {
    @apply grid gap-4 sm:grid-cols-2 max-sm:flex max-sm:flex-row max-sm:overflow-x-scroll max-sm:snap-mandatory max-sm:snap-x;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .item {
    @apply max-sm:snap-center shrink-0 w-full flex h-full;
  }

  .indices {
    @apply sm:hidden w-full flex items-center justify-center flex-row gap-4 py-8 mx-auto;
  }

  .index {
    @apply rounded-full w-3 h-3 bg-ultraviolet;

    &.active {
      @apply bg-indigo-100;
    }
  }
</style>

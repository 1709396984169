<script lang="ts">
  import { twMerge } from 'tailwind-merge';

  import { v4 } from 'uuid';
  import Icon from '../icon/icon.svelte';
  type T = $$Generic;

  let className: string | undefined = undefined;
  const uuid = v4();
  export let items: T[];
  export let groupSize = 1;
  export { className as class };

  let active = 0;
  let carousel: HTMLDivElement;
  let groups: T[][] = [];

  const scrollTo = (index: number) => {
    const elem = document.getElementById(`carousel-item-${uuid}-${index}`);
    if (elem) {
      const amount = index > active ? elem.clientWidth : -elem.clientWidth;
      carousel.scrollBy({ left: amount, behavior: 'smooth' });
      active = index;
    }
  };

  const groupItems = () => {
    for (let i = 0; i < items.length; i += groupSize) {
      groups.push(items.slice(i, i + groupSize));
    }
  };

  $: (items, groupSize), groupItems();
</script>

<div
  class={twMerge(
    'flex max-md:flex-col md:flex-row justify-between gap-8',
    className,
  )}
>
  {#if groups.length > 1}
    <div class="flex items-center justify-between">
      <button
        aria-label="scroll left"
        class="scroll-button"
        disabled={!groups[active - 1]}
        on:click={() => scrollTo(active - 1)}
      >
        <Icon name="arrow-left" />
      </button>
      <button
        aria-label="scroll right"
        class="scroll-button md:hidden"
        disabled={!groups[active + 1]}
        on:click={() => scrollTo(active + 1)}
      >
        <Icon name="arrow-right" />
      </button>
    </div>
  {/if}
  <div role="grid" class="carousel" bind:this={carousel}>
    {#each groups as items, idx}
      <div id="carousel-item-{uuid}-{idx}" class="item">
        <slot {items} />
      </div>
    {/each}
  </div>
  {#if groups.length > 1}
    <button
      aria-label="scroll right"
      class="scroll-button max-md:hidden"
      disabled={!groups[active + 1]}
      on:click={() => scrollTo(active + 1)}
    >
      <Icon name="arrow-right" />
    </button>
  {/if}
</div>

<style lang="postcss">
  .carousel {
    @apply flex grow flex-row overflow-x-scroll snap-mandatory snap-x;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .item {
    @apply snap-center shrink-0 flex h-full w-full;
  }

  .scroll-button {
    @apply shrink-0 disabled:opacity-50;
  }
</style>
